// ** React Imports
import { memo } from "react";

// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Custom Components
import PresentationTile from "./components/PresentationTile";
import MilionNumberPresenter from "components/other/MilionNumberPresenter";
import LinkComponent from "components/layout/Link";
import VipStatus from "components/user/VipStatus";

// ** Utils & Helpers
import formatMessage from "utils/formatMessage";

// #####################################################

const PresentationTiles = ({ registeredUsers }) => {
	const { t } = useTranslation("landing");

	const freeContent = formatMessage(t("landing:presentation.free.content"), {
		vip: <VipStatus status={3} height={16} key="free.content.vip" noPad />,
		supervip: (
			<VipStatus
				status={5}
				height={16}
				key="free.content.superVip"
				noPad
			/>
		),
	});

	// #####################################################

	return (
		<>
			<PresentationTile
				title={t("landing:presentation.users.title")}
				content={formatMessage(
					t(`landing:presentation.users.content`),
					{
						registeredUsers: (
							<MilionNumberPresenter
								value={registeredUsers}
								withUnit={false}
								key="registered-users-count"
							/>
						),
					}
				)}
			/>
			<PresentationTile
				title={t("landing:presentation.functions.title")}
				content={t("landing:presentation.functions.content")}
			/>
			<PresentationTile
				title={t("landing:presentation.discretion.title")}
				content={t("landing:presentation.discretion.content")}
			/>
			<PresentationTile
				title={t("landing:presentation.free.title")}
				content={freeContent}
			/>
			<PresentationTile
				title={t("landing:presentation.ads.title")}
				content={t("landing:presentation.ads.content")}
			/>
			<PresentationTile
				title={t("landing:presentation.convenience.title")}
				content={formatMessage(
					t(`landing:presentation.convenience.content`),
					{
						appLink: (
							<LinkComponent to="/app" key="app-link" underline>
								{t(`landing:presentation.convenience.link`)}
							</LinkComponent>
						),
					}
				)}
			/>
		</>
	);
};

// #####################################################

export default memo(PresentationTiles);

// #####################################################
