// ** React Imports
import { useCallback } from "react";

// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Custom Components
import Image from "components/layout/Image";

// ** Styled Components
import { StyledPicturesGrid, StyledImageContainer } from "./styles";

// #####################################################

const PicturesList = ({ images = [] }) => {
	const { t } = useTranslation(["landing"]);

	// ** Hook useCallback - Funkcja renderująca listę zdjęć
	const renderImagesGrid = useCallback(() => {
		return images?.map((imageSrc, index) => {
			const alt = t("landing:pictureItemAlt", {
				num: index + 1,
			});
			return (
				<li key={alt}>
					<StyledImageContainer>
						{imageSrc && (
							<Image
								src={imageSrc}
								widthRaw="100%"
								realWidth={240}
								realHeight={240}
								alt={alt}
								loading="lazy"
								isBlurred
								fluid
							/>
						)}
					</StyledImageContainer>
				</li>
			);
		});
	}, [images]);

	// #####################################################

	return <StyledPicturesGrid>{renderImagesGrid()}</StyledPicturesGrid>;
};

// #####################################################

export default PicturesList;

// #####################################################
