import Text from "components/typography/Text";
import { convertToMilion, floor } from "utils/math";
import { useTranslation } from "react-i18next";

const MilionNumberPresenter = ({ value, withUnit = true }) => {
	const { t } = useTranslation(["common"]);

	const convertedValue = floor(convertToMilion(value)).toLocaleString(
		"pl-PL"
	);

	return (
		<Text>
			{convertedValue} {withUnit && t("common:numbersShort:milion")}
		</Text>
	);
};

export default MilionNumberPresenter;
